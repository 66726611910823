<template>
	<div class="construction-site-page">
		<Header :pageTitle="pageTitle" />
		<SubHeader :showCompanySelect="true" />

		<Loader v-show="isRequesting" />
		<div class="construction-site-container" v-show="!isRequesting">
		<FilterSearch
			filter-action="contracts/fetchContractsOnLoad"
			:filter-parameter="getFilterParameter"
			filter-route="filters/fetchContractFilterItems"
			parameter-name="company_number"
			:parameter-value="companyNumberParam"
			:query-parameter="getQueryParameter"
			searchbar-placeholder="on_site.search_filter.construction_site.placeholder"
		/>
			<div class="contracts-container filter-parent-container" v-if="hasResults">
				<ContractCard
					v-for="item in items"
					:key="`item-${item.id}`"
					:contractId="item.id"
					:contractName="item.name"
					:contractNumber="item.contract_number"
					:constructionSiteNumber="item.building_site_number"
					:customerNumber="item.customer_order_number"
					:startDate="item.contract_date_start"
					:endDate="item.contract_date_end"
					:supplierName="item.vk_org"
					:favorite="item.favorite"
					:todayDelivery="item.today_delivery"
					:todayCallOff="item.today_call_off"
                    :activeSubscription="item.active_subscription"
                />
			</div>
			<div class="no-results" v-else-if="!hasResults && selectedFilters == '' && selectedQuery == ''">
				<span class="empty-title">{{ 'on_site.no_contract_items'.trans() }}</span>
				<div class="empty-screen-icon-container">
					<img class="amount" src="@/assets/icons/contracts-empty.svg" alt="empty_amount"/>
				</div>
				 <span class="empty-message">{{ 'on_site.no_contract_items_message'.trans() }}</span>
			</div>

			<div class="no-results" v-else>
				<span class="empty-title">{{ 'on_site.no_contract_items_filtered'.trans() }}</span>
				<div class="empty-screen-icon-container">
					<img class="amount" src="@/assets/icons/contracts-empty.svg" alt="empty_amount"/>
				</div>
				 <span class="empty-message">{{ 'on_site.no_contract_items_message_filtered'.trans() }}</span>
			</div>
		</div>

		<Loader v-if="isRequestingPaginated" class="small" />
		<Footer/>
	</div>
</template>

<script setup>
import {computed, onMounted, onUnmounted, watch} from 'vue'
	import { useStore } from 'vuex'

	import Header from '@/components/Common/Header.vue'
	import SubHeader from '@/components/SubHeader/SubHeader.vue'
	import Footer from '@/components/Common/Footer.vue'
	import ContractCard from '@/components/Dashboard/ContractCard.vue'
	import Loader from '@/components/Common/Loader.vue'
	import FilterSearch from "@/components/Common/FilterSearch.vue";

	const pageTitle = 'on_site.page-title.construction-site'.trans()
	const store = useStore()

	// Get contracts
	let items

	// Pagination and has results flag
	const hasResults = computed(() => store.state.contracts.contracts && store.state.contracts.contracts.items && store.state.contracts.contracts.items.length > 0)
	const pagination = computed(() => store.state.contracts.contracts.pagination)

	// Loading flags
	const isRequesting = computed(() => store.state.contracts.isContractsRequesting)
	const isRequestingPaginated = computed(() => store.state.contracts.isContractsPaginationRequesting)

	// Company number parameter
	const companyNumberParam = computed(() => store.state.user.selectedCompany.company_nr)

	// Query parameter from search component that needs to be put into pagination for loading more results
	let searchQueryParameter = ''
	function getQueryParameter(val) {
		searchQueryParameter = val
	}
	
	// Filters parameter from filters popup - used for pagination
	let filtersParameter = ''
	function getFilterParameter(val) {
		filtersParameter = val
	}

	const selectedFilters = computed(() => store.state.filters.selectedFilters)
	const selectedQuery = computed(() => store.state.filters.selectedQuery)

	// Pagination logic
	const initializeInfiniteScroll = () => {
		window.onscroll = () => {
			const bottomOfWindow = document.documentElement.scrollTop + window.innerHeight >= document.documentElement.offsetHeight
			if (bottomOfWindow && !isRequesting.value && !isRequestingPaginated.value) {
				const { current_page, page_size, total_items, total_pages, has_next_page } = pagination.value
				if (has_next_page && !$('.contracts-container').hasClass('hidden')) {
					if(searchQueryParameter != '') {
						store.dispatch('contracts/fetchContractsPaginated', {
							company_number: companyNumberParam.value,
							page: current_page + 1,
							page_size: page_size,
							query: searchQueryParameter,
							'filterString': filtersParameter
						})
					} else {
						store.dispatch('contracts/fetchContractsPaginated', {
							company_number: companyNumberParam.value,
							'filterString': filtersParameter,
							page: current_page + 1,
							page_size: page_size,
						})
					}

                    setActiveContractSubscriptions()
				}
			}
		}
	}

    const setActiveContractSubscriptions = () => {
        items = computed(() => store.state.contracts.contracts.items)

        setTimeout(function() {
            items.value.forEach((item, index) => {
                if (item.active_subscription == 'true') {
                    $('.construction-site-card[data-contract-id="' + item.id + '"] .contract-subscription').addClass('active fade-in')
                }
            })
        }, 1000)
    }

    watch(() => store.state.contracts.isContractsRequesting, (newValue, oldValue) => {
        if (!newValue && oldValue) {
            setActiveContractSubscriptions()
        }
    })

	onMounted(() => {
		initializeInfiniteScroll()
        store.dispatch('contracts/fetchRecentContracts')
        items = computed(() => store.state.contracts.contracts.items)
	})

	onUnmounted(() => {
		store.commit('filters/setSelectedFilters', '')
		store.commit('filters/setSelectedQuery', '')
	})
</script>

<style lang="sass" scoped>
.construction-site-page
	padding-top: 51px + 48px + 11px // header + subheader + margin from design
	padding-bottom: 81px // footer + some extra height
	min-height: calc(100vh - 51px - 48px - 81px)

.construction-site-container
	display: flex
	flex-direction: column
	gap: 10px
	justify-content: space-evenly
	padding: 0 24px 60px
	position: relative

	.contracts-container
		display: grid
		gap: 10px
		grid-template-columns: 1fr 1fr
		margin-top: 10px

		@media(max-width: 767px)
			grid-template-columns: 1fr

	.filter-parent-container
		&.hidden
			display: none

	.no-results
		margin-top: 20px
		display: flex
		flex-direction: column
		font-family: PoppinsMedium
		font-size: 12px
		color: $text-gray
		text-align: center

		.empty-screen-icon-container
			position: relative
			margin: 40px auto 30px auto
			width: 231px

		.empty-title
			font-family: PoppinsBold
			font-size: 16px

	&.hidden
		display: none
</style>