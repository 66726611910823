<template>
    <div class="contract-detail-page">
        <Header :pageTitle="pageTitle"/>
        <SubHeader :currentPage="currentPage"/>

        <Loader v-if="isRequestingDailyAmounts || isRequestingDeliveries" />
        <div v-else>
            <template v-if="(deliveryItems && deliveryItems.length) || (dailyAmountItems && dailyAmountItems.length)">
                <div class="delivery-slider-container">
                    <p class="delivery-slider-title">{{'on_site.contract_detail.delivery_slider.title'.trans()}}</p>

                    <DeliveryCardSlider v-if="recentDeliveryItems && recentDeliveryItems.length" :items="recentDeliveryItems" />

                    <div class="delivery-slider-empty" v-else>
                        {{'on_site.recent_deliveries.empty'.trans()}}
                    </div>
                </div>

                <div class="daily-amount-slider-container" v-show="dailyAmountItems && dailyAmountItems.length">
                    <p class="daily-amount-slider-title">{{'on_site.contract_detail.daily_amount_slider.title'.trans()}}</p>
                    <AmountCardSlider v-if="dailyAmountItems && dailyAmountItems.length" :items="dailyAmountItems" />
                </div>
            </template>
            <div class="contract-detail-empty-page" v-else>
                <div class="empty-page-title">{{'on_site.contract_detail.empty_page.title'.trans()}}</div>
                <img class="empty-page-image" src="@/assets/icons/truck.svg">
                <div class="empty-page-text">{{'on_site.contract_detail.empty_page.text'.trans()}}</div>
            </div>
            <div class="contract-subscription-container">
                <p class="contract-subscription-title">{{'on_site.contract_detail.contract_subscription.title'.trans()}}</p>
                <div class="setting-container" :class="{ 'requesting': requestingFlag }">
                    <div class="text">
                        <img alt="" class="contract-notification" src="../../assets/icons/icon-bell-small.svg"/>
                        <p>{{ 'on_site.contract_detail.contract_subscription.button'.trans()}}</p>
                    </div>
                    <div class="success-icon">
                        <img src="@/assets/icons/success-icon.svg">
                    </div>
                    <div class="toggler" @click="handleContractSubscription(contractId)">
                        <div class="toggler-button" :class="{ 'active': activeSubscription }"></div>
                    </div>
                </div>
            </div>
            <div class="contract-detail-buttons-container" v-if="user.buttonPermissions.includes('call-off-creation')">
                <p class="contract-detail-buttons-title">{{'on_site.contract_detail.actions.title'.trans()}}</p>
                <router-link :to="{ name: 'contract-items', params: { id: contractId }}" class="dashboard-button">
                    <span class="button-text">{{'on_site.contract_detail.calloff_creation'.trans()}}</span>
                    <span class="button-icon">
                        <img alt="..." src="@/assets/icons/amount-blue.svg"/>
                    </span>
                </router-link>
            </div>
            <div class="contract-detail-buttons-container overview" v-if="user.buttonPermissions.includes('call-off-list')">
                <router-link :to="{ name: 'calloff-list', params: {}}" class="dashboard-button">
                    <span class="button-text">{{'on_site.menu.calloff_list'.trans()}}</span>
                    <span class="button-icon">
                        <img alt="..." src="@/assets/icons/calendar-blue.svg"/>
                    </span>
                </router-link>
            </div>
        </div>
        <Footer/>
    </div>
</template>

<script setup>
    import { computed, ref, onMounted, watch } from 'vue'
    import { useStore } from 'vuex'

    import Header from '@/components/Common/Header.vue'
    import SubHeader from '@/components/SubHeader/SubHeader.vue'
    import Footer from '@/components/Common/Footer.vue'
	import Loader from '@/components/Common/Loader.vue'

    import DeliveryCardSlider from '@/components/ContractDetails/DeliveryCardSlider.vue'
    import AmountCardSlider from '@/components/ContractDetails/AmountCardSlider.vue'
    import {makeRequest} from "@/utils/makeRequest";
    import OneSignalHelper from "@/utils/OneSignalHelper";

    const store = useStore()

    store.dispatch('user/fetchButtonPermissions')

    const {contracts, user, amountItems, deliveryItems} = store.state

    const pageTitle = computed(() => contracts.selectedContract.contract_name)
    const currentPage = 'on_site.menu.contract_detail_dashboard'.trans()
    const contractId = computed(() => contracts.selectedContract.contract_id)

    const activeSubscription = ref(false)
    const requestingFlag = ref(false)

    // Recent items loading flag
    const isRequestingDailyAmounts = computed(() => amountItems.isAmountsRequesting)
    const isRequestingDeliveries = computed(() => deliveryItems.isDeliveryItemsRequesting)

    // The request for data was fired in the route guard
	const dailyAmountItems = computed(() => amountItems.recentDailyAmounts.items)
    const recentDeliveryItems = computed(() => deliveryItems.recentDeliveryItems.items)

    const pushNotificationsEnabled = ref(store.state.settings.data.PushNotification)

    const handleContractSubscription = async (contractId) => {
        requestingFlag.value = true

        try {
            const newSubscriptionStatus = activeSubscription.value == 'true' || activeSubscription.value == true ? false : true

            store.commit('contracts/setSelectedContractActiveSubscription', newSubscriptionStatus)

            await makeRequest('get', 'api_toggle_contract_subscription', {
                contractId: contractId,
                status: newSubscriptionStatus === true ? 1 : 0
            })

            activeSubscription.value = newSubscriptionStatus

            requestingFlag.value = false

            if(newSubscriptionStatus === true && !pushNotificationsEnabled.value) {
                enableGlobalPushNotifications('PushNotification')
            }

            toggleContractSubscriptionButton()
            showSuccessIcon()
        } catch (err) {
            requestingFlag.value = false
            throw err
        }
    }

    const showSuccessIcon = () => {
        $('.contract-subscription-container .success-icon')
            .css('opacity', 1)
            .delay(500)
            .animate({ opacity: 0 }, 2000)
    }

    const storeValues = store.state.settings

    const settings = computed(() => ({
        CallOff: storeValues.data && storeValues.data.CallOff,
        Reminder: storeValues.data && storeValues.data.Reminder,
        Tips: storeValues.data && storeValues.data.Tips,
        PushNotification: storeValues.data && storeValues.data.PushNotification,
        Theme: storeValues.data && storeValues.data.Theme
    }))

    const requestingFlags = {
        CallOff: ref(false),
        Reminder: ref(false),
        Tips: ref(false),
        PushNotification: ref(false),
        Theme: ref(false)
    }

    const enableGlobalPushNotifications = async (settingName) => {
        const requestingFlag = requestingFlags[settingName]

        // Already requesting
        if (requestingFlag.value) {
            return
        }

        requestingFlag.value = true

        try {
            // Toggle value
            const newValue = !storeValues.data[settingName]

            // Make request
            await makeRequest('post', 'api_update_profile_settings', {
                type: settingName,
                status: newValue,
            })

            // Update store values => this will re-render view
            store.dispatch('settings/updateUserSingleSetting', {
                settingName,
                value: newValue
            })

            // Handle change for push notifications
            if (settingName === 'PushNotification') {
                store.dispatch('user/updateNotificationSubscriptionState', newValue)

                if(newValue) {
                    // Show notification permission
                    OneSignalHelper.setUserSubscription()

                    // Subscribe user
                    OneSignalHelper.subscribeUser()
                } else {

                    // Unsubscribe user
                    OneSignalHelper.unSubscribeUser()
                }
            }

            requestingFlag.value = false
        } catch(err) {
            requestingFlag.value = false
            throw err
        }
    }

    const toggleContractSubscriptionButton = () => {
        let togglerButton = $('.contract-subscription-container .toggler-button')
        $('.contract-subscription-container .toggler').addClass('fade-in')

        if(activeSubscription.value == 'true' || activeSubscription.value == true) {
            togglerButton.addClass('active')
        } else {
            togglerButton.removeClass('active')
        }
    }

    watch(() => store.state.deliveryItems.isDeliveryItemsRequesting, (newValue, oldValue) => {
        if (!newValue && oldValue) {
            setTimeout(function() {
                toggleContractSubscriptionButton()
            }, 1000)
        }
    })

    onMounted(() => {
        activeSubscription.value = contracts.selectedContract.active_subscription

        setTimeout(function() {
            toggleContractSubscriptionButton()
        }, 1000)

    })
</script>

<style lang="sass" scoped>
.contract-detail-page
    padding-top: 51px + 48px // header + subheader
    padding-bottom: 81px // footer + some extra height
    min-height: calc(100vh - 51px - 48px - 81px)

    .setting-container
        align-items: center
        border-bottom: 1px solid rgba($border-gray, 0.3)
        display: flex
        justify-content: space-between
        text-align: left
        padding: 5px 20px 5px 20px
        background: white
        margin-bottom: 20px
        filter: drop-shadow(0px 3px 3px #01000029)

        .text
            .title
                margin-bottom: 2px
                font-family: PoppinsBold
                font-size: 12px
                letter-spacing: 0.3px
                color: $text-black

            .info
                margin-top: 0px
                font-family: PoppinsLight
                font-size: 10.5px
                letter-spacing: 0.26px
                color: $text-black

        .toggler
            margin-left: 20px
            border-radius: 14px
            height: 24px
            min-width: 45px
            background-color: $white
            box-shadow: 0 2px 4px #01000029
            opacity: 0
            cursor: pointer
            transition: 0.3s
            outline: none
            -webkit-tap-highlight-color: rgba(0,0,0,0)

            .toggler-button
                margin-top: 2px
                margin-left: 4px
                width: 20px
                height: 20px
                border-radius: 14px
                background-color: $red
                transition: .3s

                &.active
                    background-color: $bright-green
                    margin-left: 21px

            &.fade-in
                opacity: 1

        &.requesting
            cursor: not-allowed
            .toggler
                opacity: 0.68
                pointer-events: none

    .contract-detail-empty-page
        margin: 25px 13px 0px 13px
        text-align: center
        letter-spacing: 0.02px
        color: $text-gray

        .empty-page-title
            max-width: 368px
            margin: 0px auto 60px auto
            font-size: 16px
            font-family: PoppinsBold

        .empty-page-text
            max-width: 368px
            margin: 60px auto 100px auto
            font-size: 12px
            font-family: PoppinsMedium
            line-height: 15px

    .contract-detail-buttons-container
        margin: 0px auto 0px auto
        padding: 0px 13px 0px 13px
        max-width: 394px

        .dashboard-button
            width: 100%
            height: 55px
            margin-bottom: 15px
            display: flex
            justify-content: space-between
            align-items: center
            border: none
            border-radius: 3px
            filter: drop-shadow(0px 2px 2px #01000029)
            text-decoration: none
            background-color: $button-gray

            .button-text
                padding-left: 10px
                font-family: MontserratMedium
                font-size: 16px
                color: $text-blue

            .button-icon
                display: flex
                padding-right: 20px
                position: relative
                width: 55px

        &.overview
            .dashboard-button
                .button-icon
                    height: 30px

.delivery-slider-container, .daily-amount-slider-container
    margin: 0 auto
    padding: 13px 5px 0px 5px
    max-width: 394px

    .delivery-slider, .daily-amount-slider
        .construction-site-card, .daily-amount-card
            max-width: initial

    .delivery-slider-empty
        margin: 5px 8px 35px 8px
        padding: 65px 0px
        border-radius: 3px
        filter: drop-shadow(0px 2px 2px #01000029)
        font-family: PoppinsBold
        font-size: 16px
        letter-spacing: .02px
        background-color: $white
        color: $text-gray

.daily-amount-slider-container
    padding-top: 0px

.contract-subscription-container
    margin: 0 auto
    padding: 13px 13px 0px 13px
    max-width: 394px

    .setting-container
        height: 44px
        padding: 0 10px

        .text
            padding-right: 30px
            width: 100%

            p
                align-items: center
                display: flex
                line-height: 16px

        .success-icon
            opacity: 0
            position: absolute
            right: 60px
            top: 1px
            transition: 0.3s

            img
                height: 44px

        .toggler
            .toggler-button
                background-color: $dark-red

                &.active
                    background-color: $green

    .text
        display: flex

        p
            color: $text-lighter-gray
            font-family: PoppinsSemiBold
            font-size: 14px
            margin: 0 0 0 10px

.delivery-slider-title,
.daily-amount-slider-title
.contract-subscription-title,
.contract-detail-buttons-title
    margin: 0 0 5px 8px
    text-align: left
    font-family: PoppinsMedium
    font-size: 12px
    letter-spacing: 0.02px
    color: $text-gray

.dark-mode
    .contract-detail-page 
        .contract-detail-buttons-container 
            .dashboard-button
                background-color: $dark-button-gray
                border: 1px solid $border-gray

                .button-text
                    color: $dark-text-color
</style>