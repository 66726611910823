<template>
    <div class="construction-site-card" v-bind:data-contract-id="contractId">
		<router-link
			class="contract-card-link"
			@click="updateSelectedContract($event)"
			:to="{ name: 'contract-detail', params: { id: contractId } }"
		>
			<div class="construction-site-header">
				<span>{{ contractName ? contractName : '—' }}</span>
			</div>

			<table class="construction-site-card-table">
				<tr>
					<td>{{'on_site.construction_site.contract_number'.trans()}}</td>
					<td>{{ contractNumber ? contractNumber : '—' }}</td>
				</tr>
				<tr>
					<td>{{'on_site.construction_site.number'.trans()}}</td>
					<td>{{ constructionSiteNumber ? constructionSiteNumber : '—' }}</td>
				</tr>
				<tr>
					<td>{{'on_site.construction_site.customer_number'.trans()}}</td>
					<td>{{ customerNumber ? customerNumber : '—' }}</td>
				</tr>
				<tr>
					<td>{{'on_site.construction_site.start_end_date'.trans()}}</td>
					<td class="contract-date"
						:class="{ 'expired-contract': endDateFormatted < todaysDate.setDate(todaysDate.getDate() + 10) }"
					>{{ startDate }} - {{ endDate }}</td>
				</tr>
				<tr>
					<td>{{'on_site.construction_site.supplier'.trans()}}</td>
					<td>{{ supplierName ? supplierName : '—' }}</td>
				</tr>
			</table>
		</router-link>

        <div class="construction-site-icon-container">
            <div class="favorite-container"
                :class="{ favoriteOn: favorite == 'true' , favoriteOff: favorite == 'false' }"
            >
                <img class="remove-favorite-button" src="../../assets/icons/star.svg" @click="removeFavorite"/>
                <img class="add-favorite-button" src="../../assets/icons/star-border.svg" @click="setFavorite"/> 
            </div>
            <div class="phone-container" v-if="todayDelivery == 'true'">
                <img class="phone" alt="" src="../../assets/icons/phone.svg"/>
            </div>
            <div v-if="todayCallOff == 'true'">
                <img alt="" class="clock" src="../../assets/icons/clock.svg"/>
            </div>
            <div class="contract-subscription">
                <img alt="" class="contract-notification" src="../../assets/icons/icon-bell-small-blue.svg"/>
            </div>
        </div>
    </div>
</template>

<script setup>
	import { ref } from 'vue'
	import { makeRequest } from '@/utils/makeRequest.js'
	import { showErrorPopup } from '@/utils/globalSwalPopups.js'
	import { useStore } from 'vuex'

	const store = useStore()

	const props = defineProps({
		contractId: String,
		contractName: String,
		contractNumber: String,
		constructionSiteNumber: String,
		customerNumber: String,
		startDate: String,
		endDate: String,
		supplierName: String,
		favorite: String,
		todayDelivery: String,
		todayCallOff: String,
        activeSubscription: String
	})

	const contractId = ref(props.contractId)
	const contractName = ref(props.contractName)
	const contractNumber = ref(props.contractNumber)
	const constructionSiteNumber = ref(props.constructionSiteNumber)
	const customerNumber = ref(props.customerNumber)
	const startDate = ref(props.startDate)
	const endDate = ref(props.endDate)
	const supplierName = ref(props.supplierName)
	const favorite = ref(props.favorite)
	const todayDelivery = ref(props.todayDelivery)
	const todayCallOff = ref(props.todayCallOff)
    const activeSubscription = ref(props.activeSubscription)

	// Logic for setting and removing a contract from favorites
	async function setFavorite(event) {
		try {
			$(event.target).closest('.favorite-container').removeClass('favoriteOff')
			$(event.target).closest('.favorite-container').addClass('favoriteOn')
			const payload = {
				'contract_number': contractNumber.value
			}
			await makeRequest('post', 'api_add_favourite_contract', payload)

			const contractsArray = store.state.contracts.contracts.items
			const contractIndex = contractsArray.findIndex(item => item.id === contractId.value)

			const storePayload = {
				'position': contractIndex,
				'value': 'true' 
			}

			store.commit('contracts/setContractsFavorite', storePayload)
		} catch {
			showErrorPopup()
		}
	}

	async function removeFavorite(event) {
		try {
			$(event.target).closest('.favorite-container').addClass('favoriteOff')
			$(event.target).closest('.favorite-container').removeClass('favoriteOn')
			const payload = {
				'contract_number': contractNumber.value
			}
			await makeRequest('post', 'api_remove_favourite_contract', payload)

			const contractsArray = store.state.contracts.contracts.items
			const contractIndex = contractsArray.findIndex(item => item.id === contractId.value)

			const storePayload = {
				'position': contractIndex,
				'value': 'false' 
			}

			store.commit('contracts/setContractsFavorite', storePayload)

		} catch {
			showErrorPopup()
		}
	}

	// Save selected contract to store so we can use it on single pages
	function updateSelectedContract(event) {
		store.commit('contracts/setSelectedContract', {
			'contract_id': contractId,
			'contract_number': contractNumber, 
			'contract_name': contractName,
            'active_subscription': activeSubscription
		})
	}

	let endDateArray = endDate.value.split(".")
	var endDateFormatted = new Date(endDateArray[2], endDateArray[1] - 1, endDateArray[0])

	let todaysDate = new Date()
</script>

<style lang="sass" scoped>
.construction-site-card
    width: 100%
    padding: 15px 9px 9px
    background-color: $white
    filter: drop-shadow(0px 3px 3px #01000029)
    box-sizing: border-box
    text-align: left
    border-radius: 3px

    .contract-card-link
        text-decoration: none
        color: $text-black

    .construction-site-header
        padding-left: 2px
        font-family: PoppinsBold
        font-size: 14px
        letter-spacing: 0.03px
        line-height: 21px

        max-width: 340px
        overflow: hidden
        text-overflow: ellipsis
        white-space: nowrap

    .construction-site-card-table
        margin-top: 7px
        width: 100%
        font-family: PoppinsMedium
        font-size: 12px
        letter-spacing: 0.02px
        line-height: 21px
        color: $text-black

        tr
            td
                max-width: 144px
                overflow: hidden
                text-overflow: ellipsis
                white-space: nowrap

                &:first-child
                    width: 130px
                    vertical-align: baseline

                &.contract-date
                    &.expired-contract
                        color: $red

        tr > :first-child
            margin-right: 0px

    .construction-site-icon-container
        display: flex
        align-items: center
        margin: 0 auto
        max-width: 100px
        width: max-content

        div
            width: 33px

            &.phone-container
                margin-right: 2px

        img
            margin: 0 7px

        .add-favorite-button, .remove-favorite-button
            cursor: pointer

        .favoriteOn
            .add-favorite-button
                display: none

        .favoriteOff
            .remove-favorite-button
                display: none

        .contract-notification
            width: 20px

        .contract-subscription
            opacity: 0
            transition: 0.3s

            &.active
                opacity: 0.5

                &.fade-in
                    opacity: 1

.dark-mode
    .construction-site-card
        background-color: $dark-button-gray
        border: 1px solid $component-border

        .contract-card-link
            color: $dark-text-color

        .construction-site-card-table
            color: $white
</style>
