<template>
    <div class="login-page">
        <FlashMessage />
        <Announcement v-if="showAnnouncement"/>
        <div class="background-image">
            <img alt="Background Image" :src="getBackgroundImage()">
        </div>

        <div class="login-logo-container">
            <img alt="Logo" :src="getLogoUrl()">
        </div>

        <div class="login-banner-container">
            <span>{{'on_site.login.banner_text_regular'.trans()}}</span> <br>
            <span class="bold-banner-text">{{'on_site.login.banner_text_bold'.trans()}}</span>
        </div>

        <div class="invalid-login-data-warning hidden">
            <img class="hide-warning" alt="Hide" src="@/assets/icons/menu-close.svg" @click="hideWarning">
            {{'on_site.login.invalid_login_data'.trans()}}
        </div>

        <div class="login-inputs-container">
            <input class="standard-input" v-model="eMail" :placeholder="placeholders.email"/>
            <div class="password-input">
                <input class="standard-input" v-model="password" :type="passwordInputType" :placeholder="placeholders.password"/>
                <img class="show-pass-icon" @click="togglePasswordVisibility" alt="Show password" :src="togglePasswordVisibilityIcon">
            </div>
            <Button light-blue bold full-width :clickEvent="login" :isLoading="isSubmitting">
                {{ submitButtonText }}
            </Button>
            <p class="forgot-password-link">
                <router-link :to="{ name: 'password-recovery' }">{{'on_site.login.forgot_password'.trans()}}</router-link>
            </p>
        </div>

        <div class="login-invitation-button-container">
            <p>{{'on_site.login.first_registration'.trans()}}</p>
            <Button :to="{ name: 'register' }" light-blue bold full-width>
                {{ 'on_site.login.registration_button'.trans() }}
            </Button>
        </div>
    </div>
</template>

<script setup>
import { ref, onUnmounted, onMounted, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { makeRequest } from '@/utils/makeRequest'
import { showErrorPopup } from '@/utils/globalSwalPopups.js'
import OneSignalHelper from '@/utils/OneSignalHelper'
import FlashMessage from '@/components/Common/FlashMessage.vue'
import Announcement from '@/components/Common/Announcement.vue'
import Button from '@/components/Common/Button.vue'

const store = useStore()
const router = useRouter()

const showAnnouncement = computed(() => store.state.announcement.doneRequesting && store.state.announcement.showAnnouncement)

// Starting values for variables bound in html
const eMail = ref('')
const password = ref('')
const togglePasswordVisibilityIcon = ref(require('@/assets/icons/eye.svg'))
const passwordInputType = ref('password')

const isSubmitting = ref(false)
const submitButtonText = 'on_site.login.button'.trans()

const placeholders = {
    email: 'on_site.login.placeholder.email'.trans(),
    password: 'on_site.login.placeholder.password'.trans()
}

const getLogoUrl = () => window.logoPath

const getBackgroundImage = ()  => window.innerWidth > 500 ? window.background : window.backgroundx2

const queryString = window.location.search
const UrlParams = new URLSearchParams(queryString)
if(UrlParams.has('_im')) {
    _login(UrlParams.get('_im'))
}

function togglePasswordVisibility(event) {
    $(event.target).toggleClass('hide-password')

    if ($(event.target).hasClass('hide-password')) {
        togglePasswordVisibilityIcon.value = require('@/assets/icons/eye_off.svg')
        passwordInputType.value = 'text'
    } else {
        togglePasswordVisibilityIcon.value = require('@/assets/icons/eye.svg')
        passwordInputType.value = 'password'
    }
}

function login() {
    _login()
}

async function _login(token = null) {
    isSubmitting.value = true
    const url = Routing.generate('api-login', {}, true)

    try {
        const payload = new FormData()

        if(token !== null) {
            payload.append('_im', token)
        } else {
            payload.append('username', eMail.value)
            payload.append('password', password.value)
        }

        const { shortLocale } = window.appConfig
        const loginResponse = await axios.post(url, payload, {
            headers: {
                'Accept-Language': shortLocale
            }
        })

        store.commit('setAccessToken', loginResponse.data.data.access_token)

        // Make request and mutate store => like this we can "await" it
        const userResponse = await makeRequest('post', 'api-user')

        // Update user data store values
        store.commit('user/setUserData', userResponse.data)

        // Set selected Company
        const { available_companies } = userResponse.data
        if(available_companies && available_companies.length > 0) {
            const selectedCompany = available_companies[0]

            store.commit('user/setSelectedCompany', selectedCompany)
        }

        // Set loggedIn flag
        store.commit('userLoggedIn')

        OneSignalHelper.setUserSubscription()
        OneSignalHelper.subscribeUser()
        OneSignalHelper.setUserDevicePermission()

        isSubmitting.value = false
        const lastAccessedRoute = !Array.isArray(store.state.lastAccessedRoute.any) ? '' : store.state.lastAccessedRoute.any[0]

        if (lastAccessedRoute && token == null) {
            router.push(lastAccessedRoute)
        } else {
            router.push({ name: 'dashboard' })
        }

    } catch(error) {
        if (error.response && error.response.status == 403 && error.response.data &&
            error.response.data.error) {
            showErrorPopup(error.response.data.error)

            return
        }

        isSubmitting.value = false

        if (error.response && error.response.status == 503) {
            router.push({ name: 'maintenance' })

        } else if (error.response && error.response.status == 401) {
            $('.invalid-login-data-warning').removeClass('hidden')

        } else {
            showErrorPopup()
        }
    }
}

function hideWarning() {
    $('.invalid-login-data-warning').addClass('hidden')
}

onMounted(() => {
    // Fetch the global notification/message that appears like a popup on entrance and show it if necessary
    store.dispatch('announcement/fetchAnnouncementData')
})

onUnmounted(() => {
    let currentTheme = '10'
    if(store.state.user.data) {
        currentTheme = store.state.user.data.theme
    } else {
        currentTheme = store.state.settings.data.Theme
    }

    const darkModeActivated = store.state.settings && store.state.settings.dark_mode_activated
    const isDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches

    if(currentTheme == 10) {
        if(!(darkModeActivated.value == isDarkMode)) {
            store.commit('settings/setDarkMode', isDarkMode)
        }
    } else if (currentTheme == 20 || currentTheme == null) {
        store.commit('settings/setDarkMode', false)
    } else if(currentTheme == 30) {
        store.commit('settings/setDarkMode', true)
    }
})
</script>

<style lang="sass" scoped>
.login-page
    min-height: 100vh

    p
        margin: 0px
        font-size: 12px
        line-height: 24px
        color: $white

    button
        font-family: PoppinsRegular
        font-size: 16px
        line-height: 25px

    input
        padding-left: 12px
        letter-spacing: 0.96px
        width: 250px

    .login-logo-container
        position: relative
        z-index: 1

        img
            margin-top: 30px
            max-height: 200px

    .flash-message-container
        z-index: 1

    .background-image
        img
            height: 100%
            left: 0
            object-fit: cover
            position: absolute
            width: 100%
            z-index: 0

    .login-banner-container
        margin-bottom: 90px
        padding: 25px 15px 25px 15px
        font-family: PoppinsRegular
        font-size: 19px
        text-transform: uppercase
        letter-spacing: 0.18em
        color: $white
        background-color: $darkest-blue
        position: relative
        z-index: 1

        .bold-banner-text
            font-family: PoppinsSemiBold

    .invalid-login-data-warning
        position: relative
        width: 90%
        max-width: 800px
        margin: -50px auto 50px auto
        padding-top: 10px
        padding-bottom: 10px
        font-size: 14px
        color: $white
        background-color: $login-error-red
        border-radius: 4px
        z-index: 1

        .hide-warning
            position: absolute
            height: 30px
            padding: 10px
            top: -2px
            right: -2px

    .login-inputs-container
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center
        position: relative
        z-index: 1
        width: 250px
        margin: auto

        .forgot-password-link
            a
                text-decoration: none
                color: $white

                &:hover
                    text-decoration: underline

        p
            margin-top: 7px
            font-family: PoppinsBold
            letter-spacing: 0.02pt

        .password-input
            position: relative

            .show-pass-icon
                position: absolute
                padding: 15px 10px 15px 10px
                top: 3px
                right: 8px

                &.hide-password
                    top: -1px

    .login-invitation-button-container
        margin: 72px auto auto
        padding-bottom: 72px
        position: relative
        width: 250px
        z-index: 1

        p
            font-family: PoppinsMedium
            letter-spacing: 0.06pt

        a
            text-decoration: none
            color: $white
</style>